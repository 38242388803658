<script>
import { Line } from 'vue-chartjs';
import moment from 'moment';
import tinycolor from 'tinycolor2';
// @ts-ignore
import cssVars from '../css/colors.scss';
import courseMixins from '../store/mixins/courseMixins';

export default {
    name: 'StudentBreakdownLineChart',
    components: {
        'line-chart': Line,
    },
    extends: Line,
    mixins: [courseMixins],
    props: {
        assignments: {
            type: Array,
            required: true,
        },
        category: {
            type: Object,
            required: true,
        },
        clickedAssignment: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    computed: {
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        gradeTemplate() {
            if (!this.course) return null;
            return this.course.gradeTemplate;
        },
        averageMethod() {
            if (!this.gradeTemplate) return 'Points';
            return this.course.gradeTemplate.averageMethod;
        },
        labels() {
            return this.gradedAssignments.map((assignment) => `${assignment.courseWorkTitle} - ${moment(assignment.publishDate).format('MMM, Do')}`);
        },
        plotData() {
            const { averageMethod } = this;
            return this.gradedAssignments.map((a) => {
                if (a.googleCourseWorkId) {
                    if (averageMethod == 'Points') {
                        if (a.grade && a.grade.google && a.grade.google.pointsEarned) {
                            return a.grade.google.pointsEarned;
                        }
                    } else if (a.grade && a.grade.google && a.grade.google.pointsEarned) {
                        return Math.round(a.grade.google.pointsEarned * 100);
                    }
                } else if (averageMethod == 'Points') {
                    if (a.grade && a.grade.syncGrades && a.grade.syncGrades.pointsEarned) {
                        return a.grade.syncGrades.pointsEarned;
                    }
                } else if (a.grade && a.grade.syncGrades && a.grade.syncGrades.pointsEarned) {
                    return Math.round(a.grade.syncGrades.pointsEarned * 100);
                }
                return 0;
            });
        },
        gradedAssignments() {
            return this.assignments.filter((assignment) => Boolean(assignment.grade && assignment.grade.syncGrades));
        },
    },
    watch: {
        assignments() {
            this.updateChart();
        },
    },
    mounted() {
        this.updateChart();
    },
    methods: {
        handleClick(point, event) {
            const v = this;
            const item = event[0];
            if (!item) return;
            const assignment = v.assignments[item._index];
            v.clickedAssignment(assignment);
        },
        updateChart() {
            const v = this;

            const chartColor = v.category && v.category.scaled ? v.category.scaled.color || v.category.color : 'Grey';
            const hexColor = cssVars[chartColor === 'White' ? 'Grey' : chartColor];
            const color = tinycolor(hexColor);

            const ctx = this.$refs.canvas.getContext('2d');
            const gradient = ctx.createLinearGradient(0, 0, 0, 240);
            gradient.addColorStop(0, color.setAlpha(0.4).toRgbString());
            gradient.addColorStop(1, color.setAlpha(0.2).toRgbString());
            if (v.plotData.length <= 1) return;

            const data = {
                labels: v.labels,
                datasets: [{
                    label: 'Grade',
                    backgroundColor: gradient,
                    borderColor: color.brighten(0.8).setAlpha(0.8).toRgbString(),
                    pointBackgroundColor: 'rgba(0, 0, 0, 0)',
                    pointBorderColor: 'rgba(0, 0, 0, 0)',
                    pointHoverBackgroundColor: '#fd3995',
                    pointHoverBorderColor: 'rgba(0, 0, 0, 0.1)',
                    data: v.plotData,
                }],
            };
            const options = {
                title: { display: false },
                tooltips: {
                    // Disable the on-canvas tooltip
                    // from https://www.chartjs.org/docs/2.9.4/configuration/tooltip.html
                    enabled: false,
                    custom(tooltipModel) {
                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            const titleLines = tooltipModel.title || [];
                            const bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = '<thead>';

                            titleLines.forEach((title) => {
                                innerHtml += `<tr><th>${title}</th></tr>`;
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach((body, i) => {
                                const colors = tooltipModel.labelColors[i];
                                let style = `background:${colors.backgroundColor}`;
                                style += `; border-color:${colors.borderColor}`;
                                style += '; border-width: 2px;';
                                const span = `<span style="${style}"></span>`;
                                innerHtml += `<tr><th>${span}${body}</th></tr>`;
                            });
                            innerHtml += '</tbody>';

                            const tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        const position = this._chart.canvas.getBoundingClientRect();

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.zIndex = 1051;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.backgroundColor = '#ffffff';
                        tooltipEl.style.left = `${position.left + window.pageXOffset + tooltipModel.caretX}px`;
                        tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = `${tooltipModel.bodyFontSize}px`;
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = '10px';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.borderRadius = '5px';
                        tooltipEl.style.boxShadow = 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px';
                    },
                },
                legend: { display: false },
                responsive: true,
                maintainAspectRatio: true,
                onClick: v.handleClick,
                scales: {
                    xAxes: [
                        {
                            display: false,
                            gridLines: false,
                            scaleLabel: { display: true, labelString: 'Month' },
                        },
                    ],
                    yAxes: [
                        {
                            display: false,
                            gridLines: false,
                            scaleLabel: { display: false, labelString: 'Value' },
                            ticks: { beginAtZero: true },
                        },
                    ],
                },
                elements: {
                    line: { tension: 0.19 },
                    point: { radius: 4, borderWidth: 12 },
                },
                layout: {
                    padding: {
                        left: 0, right: 0, top: 0, bottom: 0,
                    },
                },
            };

            v.renderChart(data, options);
        },
    },
};
</script>
